/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import * as React from 'react';
import FreeTimeSection from '../components/About/FreeTimeSection';
import HeaderAbout from '../components/About/HeaderAbout';
import HumorHistory from '../components/About/HumorHistory';
import VideoSection from '../components/About/VideoSection';
import { MainLayout } from '../components/MainLayout';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import MyHistory from '../components/About/MyHistory';
import { seoData } from '../static/seoData';

const OMnie = () => {
  return (
    <MainLayout>
      <HeaderAbout />
      <MyHistory />
      <HumorHistory />
      <VideoSection />
      <FreeTimeSection />
    </MainLayout>
  );
};

export default OMnie;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.about[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.about}
    />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import SectionWrapper from '../Common/SectionWrapper';
import { theme } from '../../styles/theme';
import * as S from './MyHistory.styles';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

const History = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
      <S.OuterWrapper>
        <SectionHeaderText>
          {t('about_me_title')} <S.HeaderSpan>Maria Kmita</S.HeaderSpan>
        </SectionHeaderText>
        <S.InnerWrapper>
          <S.ContentWrapper>
            <S.HeaderDescription>
              {t('about_me_description1')}
            </S.HeaderDescription>
            <S.HeaderDescriptionLi>
              {t('about_me_description1_li1')}
            </S.HeaderDescriptionLi>
            <S.HeaderDescriptionLi>
              {t('about_me_description1_li2')}
            </S.HeaderDescriptionLi>
            <S.HeaderDescriptionLi>
              {t('about_me_description1_li3')}
            </S.HeaderDescriptionLi>
            <S.HeaderDescriptionLi>
              {t('about_me_description1_li4')}
            </S.HeaderDescriptionLi>
            <S.HeaderDescriptionLi>
              {t('about_me_description1_li5')}
            </S.HeaderDescriptionLi>
            <S.Subheader>{t('about_history_subheader1')}</S.Subheader>
            <S.Paragraph>{t('about_history_subheader2')}</S.Paragraph>
            <S.Paragraph>{t('about_history_content1')}</S.Paragraph>
            <S.Paragraph>{t('about_history_content2_title')}</S.Paragraph>
            <S.List>
              <S.ListItem>{t('about_history_content2_item1')}</S.ListItem>
              <S.ListItem>{t('about_history_content2_item2')}</S.ListItem>
              <S.ListItem>{t('about_history_content2_item3')}</S.ListItem>
            </S.List>
            <S.Paragraph>{t('about_history_content3')}</S.Paragraph>
          </S.ContentWrapper>
          <S.ImageWrapper>
            {/* TODO: add new photo */}
            <StaticImage
              src="../../assets/images/maria-kmita-round.png"
              alt="maria kmita"
              placeholder="blurred"
            />
          </S.ImageWrapper>
        </S.InnerWrapper>
      </S.OuterWrapper>
    </SectionWrapper>
  );
};

export default History;
